import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, NavLink } from 'react-router-dom';
import TitleShape from "../../Assets/images/star-home/title_img.png"
import Collection1 from "../../Assets/images/star-home/Ex/Wedding.png"
import Collection2 from "../../Assets/images/star-home/Ex/Ring.png"
import Collection3 from "../../Assets/images/star-home/Ex/Earring.png"
import Collection4 from "../../Assets/images/star-home/Ex/Bracelet.png"
import Collection5 from "../../Assets/images/star-home/Ex/Pendant.png"

const ExploreCollection = () => {

    var explore = {
        dots: false,
        infinite: false,
        speed: 500,
        accessibility: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>

            <section className="sj_explore_collection_section">
                <Container fluid>
                    <Row>
                        <Col lg="12" col="12">
                            <div className="sj_title_main" >
                                <h2> EXPLORE OUR COLLECTION </h2>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='p-0'>

                            <Slider {...explore} className='sj_explore_collection_slider' data-aos="fade-up">
                                {/* <div className="sj_explore_item_main" data-aos="fade-up" data-aos-duration="700">
                                    <img src={Collection1} />
                                    <div className="sj_explore_hover_content">
                                        <div>
                                            <div className='sj_explore_title'>
                                                <h3> Necklace </h3>
                                            </div>
                                            <p> Enhance your neck with our jaw dropping necklaces that flatter a simple as well as bold look and looks good on any attire. </p>
                                            <button className='sj_shop_fill_btn'> <NavLink to="/jewelry/necklaces"> Shop Now </NavLink>  </button>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="sj_explore_item_main" data-aos="fade-up" data-aos-duration="1100">
                                    <img src={Collection2} />
                                    <div className="sj_explore_hover_content">
                                        <div>
                                            <div className='sj_explore_title'>
                                                <h3> Engagement Rings </h3>
                                            </div>
                                            <p> Select your perfect Engagement Ring with diamond. Choose from wide range of designer rings. </p>
                                            <button className='sj_shop_fill_btn'> <NavLink to="/engagement-rings"> Shop Now </NavLink></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="sj_explore_item_main" data-aos="fade-up" data-aos-duration="1100">
                                    <img src={Collection1} />
                                    <div className="sj_explore_hover_content">
                                        <div>
                                            <div className='sj_explore_title'>
                                                <h3> Wedding Rings </h3>
                                            </div>
                                            <p> Find the perfect symbol of your love.
                                                Discover eternity diamond rings, classic
                                                bands, and unique styles. </p>
                                            <button className='sj_shop_fill_btn'> <NavLink to="/signature-collection"> Shop Now </NavLink></button>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="sj_explore_item_main" data-aos="fade-up" data-aos-duration="1500">
                                    <img src={Collection3} />
                                    <div className="sj_explore_hover_content">
                                        <div>
                                            <div className='sj_explore_title'>
                                                <h3> earrings </h3>
                                            </div>
                                            <p> Our earrings will add grace to your entire attire. We have a wide range of hoops, studs, and drop earrings. </p>
                                            <button className='sj_shop_fill_btn'> <NavLink to="/jewelry/earrings"> Shop Now </NavLink> </button>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="sj_explore_item_main" data-aos="fade-up" data-aos-duration="2100">
                                    <img src={Collection4} />
                                    <div className="sj_explore_hover_content">
                                        <div>
                                            <div className='sj_explore_title'>
                                                <h3> bracelet </h3>
                                            </div>
                                            <p> Express yourself by accessorizing your wrists. We have sleek bracelets, tennis bracelets, bangles and many more options to choose from. </p>
                                            <button className='sj_shop_fill_btn'> <NavLink to="/jewelry/bracelets"> Shop Now </NavLink> </button>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="sj_explore_item_main" data-aos="fade-up" data-aos-duration="3100">
                                    <img src={Collection5} />
                                    <div className="sj_explore_hover_content">
                                        <div>
                                            <div className='sj_explore_title'>
                                                <h3> Pendants</h3>
                                            </div>
                                            <p> Stunning, classy and spectacular. Add glamour to a simple chain or necklace by adding our unique designed pendants.</p>
                                            <button className='sj_shop_fill_btn'> <NavLink to="/pendants"> Shop Now </NavLink></button>
                                        </div>
                                    </div>
                                </div>


                            </Slider>
                        </Col>
                    </Row>

                </Container>
            </section>

        </>
    );
}
export default ExploreCollection;