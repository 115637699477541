import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../../Assets/css/home.css";

const Insta = () => {
    return (
        <>
           <div className='mb-5'>
           <Container>
                <Row>
                    <div className="rcs_news_content1 w-100 mt-5 mb-3" >
                        <h2 className='text-center'>View our Instagram</h2>
                    </div>
                </Row>
            </Container>
            <iframe src="https://instagram.demobw.live/platinum-days/" title="platinum" frameBorder={0} width="100%" className="insta-iframe" />
           </div>
        </>
    )
}

export default Insta;