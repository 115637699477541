import React from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, NavLink } from "react-router-dom";
import PeopleSayImg from "../../Assets/images/star-home/people-img1.png";
import Rating from "@mui/material/Rating";
import GoogleImg from "../../Assets/images/google.png";
import YelpImg from "../../Assets/images/yelp.png";



const UserReview = [

  {
    username: "Chaahat Dhawan",
    rating: 5,
    review: "Shrey was a pleasure to work with while creating a custom ring for my wife. He was able to create a beautiful custom eternity ring that matched my specs and budget! I would definitely work with him again and recommend"
  },

  {
    username: "Riya Patel",
    rating: "5",
    review: "I had a wonderful experience with PlatinumDays. I had my engagement ring, my fiance’s wedding band, my wedding band and my modern mangalsutra made by them and they were all perfect!!! They did everything in 2-2.5 weeks and quality is great.."
  },

  {
    username: "Upendra Naidoo",
    rating: 5,
    review: "It was absolute pleasure working with Platinum Days to craft the perfect engagement ring. I had a rough idea of what I wanted and was able to iterate with them until the design and stone choices were seemingly matched with high quality "
  },

  {
    username: "Marlon Rodriguez",
    rating: 5,
    review: "I’m a little upset I couldn’t upload the video, but man this ring looks amazing, super happy with how it turned out. Great price and it was a pleasure working with the owner from the start of the process to the very end."
  },


]

const PeopleSay = () => {
  const [value, setValue] = React.useState(5);

  var People = {
    dots: false,
    centerPadding: "60px",
    infinite: true,
    fade: true,
    speed: 500,
    centerMode: true,
    accessibility: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  return (
    <>
      <section className="sj_whatPeopleSay_section">
        <Container className="rcs_custom_home_container ">
          <Row>
            <Col lg={6} className="offset-lg-1">
              <div
                className="sj_whatPeople_title"
              >
                <h3> What People Say </h3>
              </div>
            </Col>
          </Row>

          <Slider {...People} className="sj_people_say_slider">
            {UserReview?.map(customdata =>
              <Col lg={12} className="">
                <div className="sj_people_slider_item">
                  <div className="sj_people_slide_content_left">
                    <h3>{customdata?.username}</h3>
                    {/* <span className="sj_people_review_text"> Google Review </span> */}
                    <div>
                      <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly />
                    </div>

                    <p>  {(customdata?.review)?.slice(0, 200)}{customdata?.review?.length > 200 ? '...' : ""}</p>

                    <NavLink to="#" onClick={() => window.open("https://www.google.com/search?q=platinums+days580+5th+Ave+%231221%2C+New+York%2C+NY+10036%2C+United+States&oq=platinums+days580+5th+Ave+%231221%2C+New+York%2C+NY+10036%2C+United+States&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCTE1MTk3ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x89c2599ff46a3193:0x6098aaeb6e017a5d,1,,,,", "_blank")}>  Read More </NavLink>

                    {/* <Rating name="half-rating-read" defaultValue={5} precision={customdata?.rating} size="medium" readOnly /> */}

                    <Image
                      src={GoogleImg}
                      alt="Google Image"
                      className="ad_google_review_img"
                      onClick={() => window.open("https://www.google.com/search?q=platinums+days580+5th+Ave+%231221%2C+New+York%2C+NY+10036%2C+United+States&oq=platinums+days580+5th+Ave+%231221%2C+New+York%2C+NY+10036%2C+United+States&gs_lcrp=EgZjaHJvbWUyBggAEEUYOdIBCTE1MTk3ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8#lrd=0x89c2599ff46a3193:0x6098aaeb6e017a5d,1,,,,", "_blank")}
                    />

                  </div>

                  <div className="sj_people_slide_content_right">
                    <img src={PeopleSayImg} />
                  </div>
                </div>

              </Col>
            )}

          </Slider>
        </Container>
      </section>
    </>
  );
};
export default PeopleSay;
