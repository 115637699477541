import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Assets/css/home.css";
import { Helmet } from 'react-helmet';
import BannerSlider from './Bannerslider';
import Insta from './insta';
import ExploreCollection from './ExploreCollection';
import Virtualappointment from './VirtualAppointment';
import Customer from './Customer';
import ShopDiamondShape from "./ShopDiamondShape";
import Gemstones from "./GemstoneShop";
import RingStyle from "./RingStyle";
import Ringbuilderhome from "./Ringbuilderhome";
import SignupTo from "./SignupTo";
import AOS from 'aos';
import 'aos/dist/aos.css' ;
import { useSelector } from 'react-redux';  
import LooseDiamond from "./LooseDiamond";
import PeopleSay from "./PeopleSay";

const Home = () => {
  const metaDetails = useSelector(state => state.persistedReducer.meta.meta)

  useEffect(() => {
    AOS.init({
      duration : 1000,
      // disable: 'mobile'
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaDetails.title}</title>
        <meta name="description" content={metaDetails.description}></meta>
        <meta name="keywords" content={metaDetails.keywords}></meta>
        {/*Og Tags */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaDetails.title} />
        <meta property="og:description" content={metaDetails.description} />
        <meta property="og:url" content={metaDetails.url} />
        <meta property="og:site_name" content="Platinumdays " />
      </Helmet>

      <BannerSlider/>
      <ExploreCollection />
      {/* <RingStyle />             */}

      {/* <Ringbuilderhome /> */}
      {/* <LooseDiamond /> */}
      {/* <ShopDiamondShape />   */}
      <PeopleSay /> 
      <Insta />   
      {/* <Gemstones />
      <Virtualappointment/>       */}
      {/* <Customer /> */}
      {/* <Insta /> */}
      {/* <SignupTo /> */}

      {/* <AboutSection/> */}
      {/* <Colordiamond /> */}
      {/* <Letestcreation/> */}
      {/* <ServiceSection /> */}
      {/* <SignupTo /> */}
      {/* <Newsletter /> */}
      {/* <Featured /> */}
      {/*Collection1 :- Wedding band,Diamonds Earrings,Fine Jewelry */}
      {/* <Collection1 /> */}
      {/* <StateJewelry /> */}
      {/*Collection2 :- Pendants,Bracelets,Gemstones */}
      {/* <Collection2 /> */}
      {/* <IndianJewelry /> */}
      {/* <Philanthropy /> */}
      {/* <Testimonial /> */}
      {/* <Insta /> */}
      {/* <Newsletter /> */}
    </>
  )
}

export default Home;